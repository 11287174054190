import { useCallback, type FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { matchRoutes, useLocation, useParams } from 'react-router'
import { trackMixpanelEvent } from '../../../features/analytics/analyticsSlice'
import { setIsHelpScoutBeaconFailedToOpenModalOpen } from '../../../features/helpScout/helpScoutSlice'
import { routes } from '../../../routes/Routes'
import { contactSupportViaHelpScoutAsks } from '../../../utils/helpScout'
import { HelpScoutSupportLinkMessages } from './messages'

export interface HelpScoutSupportLinkProps {
  /** An optional prop passed down to apply Tailwind classnames to the anchor element. */
  classNames?: string
  /** An optional prop passed down to set an ID attribute for an anchor element. */
  id?: string
  /** The text that will have applied link styles and will open Help Scout when clicked. */
  linkedText: string
}

export const HelpScoutSupportLink: FunctionComponent<HelpScoutSupportLinkProps> = ({ classNames, id, linkedText }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const matchedRoutes = matchRoutes(routes, location)
  const currentRouteId = matchedRoutes && matchedRoutes.length > 0 ? matchedRoutes[0]?.route.id : undefined
  const {
    containerGroupName,
    inferenceEndpointName,
    jobQueueName,
    organizationName,
    projectName,
    recipeDeploymentName,
    recipeName,
  } = useParams()

  const setIsHelpScoutBeaconFailedToLoadModalOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsHelpScoutBeaconFailedToOpenModalOpen({ isOpen }))
    },
    [dispatch],
  )

  const handleHelpScoutFailedToOpenMixpanelEventTrack = useCallback(() => {
    currentRouteId &&
      dispatch(
        trackMixpanelEvent({
          event: 'Event', // TODO: Change this to a more descriptive event name
          properties: {
            label: HelpScoutSupportLinkMessages.helpScoutWidgetFailedViaContactSupportMixpanelEventLabeL.defaultMessage,
            path: currentRouteId,
            organizationName,
            projectName,
            recipeName,
            resourceName: containerGroupName ?? recipeDeploymentName,
            jobQueueName,
            inferenceEndpointName,
          },
        }),
      )
  }, [
    containerGroupName,
    dispatch,
    jobQueueName,
    organizationName,
    currentRouteId,
    projectName,
    recipeDeploymentName,
    recipeName,
    inferenceEndpointName,
  ])

  const handleContactSupportClick = () => {
    contactSupportViaHelpScoutAsks(
      setIsHelpScoutBeaconFailedToLoadModalOpen,
      handleHelpScoutFailedToOpenMixpanelEventTrack,
    )
  }
  return (
    <span onClick={handleContactSupportClick} id={id} className={`cursor-pointer underline ${classNames ?? ''}`}>
      {linkedText}
    </span>
  )
}
