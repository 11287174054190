import type { CreateContainerGroup } from '@saladtechnologies/openapi-cloud-portal-browser'
import { Dict } from 'mixpanel-browser'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import {
  trackMixpanelElementClickedEvent,
  trackMixpanelEvent,
  trackMixpanelPageViewedEvent,
} from '../../features/analytics/analyticsSlice'
import {
  selectIsBillingInformationMissingModalShowing,
  selectIsContainerGroupDeploymentQuotaExceededModalShowing,
  selectIsContainerGroupInstancesQuotaExceededModalShowing,
  selectOrganizationDisplayName,
  selectProjectDisplayName,
} from '../../features/createContainerGroup/createContainerGroupSelectors'
import {
  createContainerGroup,
  getCreateContainerGroupWizardData,
  setRequiredActionModalShowingState,
} from '../../features/createContainerGroup/createContainerGroupSlice'
import type { RequiredActionModal } from '../../features/createContainerGroup/models'
import { selectDuplicateContainerGroup } from '../../features/duplicateContainerGroup/duplicateContainerGroupSelectors'
import { duplicateContainerGroupRemoved } from '../../features/duplicateContainerGroup/duplicateContainerGroupSlice'
import {
  selectContainerGatewayOptionsEnabled,
  selectContainerGroupPriorityEnabled,
  selectJobQueueAutoscalerEnabled,
} from '../../features/featureFlags/featureFlagsSelectors'
import { selectGpuClasses } from '../../features/gpuClasses/gpuClassesSelectors'
import { selectJobQueues } from '../../features/jobQueues/jobQueuesSelectors'
import { configuresJobQueuesEntityId } from '../../features/jobQueues/utils'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectProfileEmail } from '../../features/profile/profileSelectors'
import { selectContainerGroupInstanceQuota } from '../../features/quotas/quotasSelectors'
import { selectRamOptions } from '../../features/ramOptions/ramOptionsSelectors'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { selectStorageOptions } from '../../features/storageOptions/storageOptionsSelectors'
import { createContainerGroupPageRoutePath } from '../../routes/routePaths'
import { getOrganizationBillingPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import {
  getRequestContainerGroupInstanceQuotaIncreaseLink,
  getRequestContainerGroupQuotaIncreaseLink,
} from '../../utils'
import { CreateContainerGroupPage } from './CreateContainerGroupPage'

export const ConnectedCreateContainerGroupPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { organizationName = '', projectName = '' } = useParams()
  const navigate = useNavigate()
  const containerGroupPriorityEnabled = useAppSelector(selectContainerGroupPriorityEnabled)
  const jobQueueAutoscalerEnabled = useAppSelector(selectJobQueueAutoscalerEnabled)
  const containerGatewayOptionsEnabled = useAppSelector(selectContainerGatewayOptionsEnabled)
  const gpuOptions = useAppSelector((state) => selectGpuClasses(state.gpuClasses, organizationName))
  const ramOptions = useAppSelector((state) => selectRamOptions(state.ramOptions))
  const storageOptions = useAppSelector((state) => selectStorageOptions(state.storageOptions))

  const isCreateContainerPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'createContainerGroup'),
  )
  const isGetCreateContainerGroupWizardPageDataPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getCreateContainerGroupPageData'),
  )
  const isBillingInformationMissingModalShowing = useAppSelector(selectIsBillingInformationMissingModalShowing)
  const isContainerGroupDeploymentQuotaExceededModalShowing = useAppSelector(
    selectIsContainerGroupDeploymentQuotaExceededModalShowing,
  )
  const isContainerGroupInstancesQuotaExceededModalShowing = useAppSelector(
    selectIsContainerGroupInstancesQuotaExceededModalShowing,
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const maxReplicaCount = useAppSelector((state) => selectContainerGroupInstanceQuota(state, organizationName))
  const organizationDisplayName = useAppSelector(selectOrganizationDisplayName)
  const projectDisplayName = useAppSelector(selectProjectDisplayName)
  const duplicateContainerGroup = useAppSelector((state) =>
    selectDuplicateContainerGroup(state.duplicateContainerGroup, organizationName),
  )
  const email = useAppSelector(selectProfileEmail)
  const jobQueues = useAppSelector((state) =>
    selectJobQueues(state.jobQueues, configuresJobQueuesEntityId(organizationName, projectName)),
  )

  const linkToRequestIncreaseForContainerGroupInstancesQuotas = getRequestContainerGroupInstanceQuotaIncreaseLink(
    email as string,
    organizationName,
  )
  const linkToRequestIncreaseForContainerGroupQuotas = getRequestContainerGroupQuotaIncreaseLink(
    email as string,
    organizationName,
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleSetModalShowingState = useCallback(
    (modal: RequiredActionModal, showing: boolean) => {
      dispatch(setRequiredActionModalShowingState({ requiredAction: modal, showing: showing }))
    },
    [dispatch],
  )

  const onCreateContainerGroup = useCallback(
    (values: CreateContainerGroup) => {
      dispatch(createContainerGroup({ organizationName, projectName, createContainerGroup: values }))
    },
    [dispatch, organizationName, projectName],
  )

  const onTrackMixpanelEvent = useCallback(
    (event: string, properties: Dict) => {
      dispatch(
        trackMixpanelEvent({
          event,
          properties: {
            ...properties,
            path: createContainerGroupPageRoutePath,
            organizationName,
            projectName,
          },
        }),
      )
    },
    [dispatch, organizationName, projectName],
  )

  const onRecordMixpanelElementClickedEvent = useCallback(
    (label: string, dockerCommand?: string) => {
      dispatch(
        trackMixpanelElementClickedEvent({
          label,
          path: createContainerGroupPageRoutePath,
          organizationName,
          projectName,
          dockerCommand,
        }),
      )
    },
    [dispatch, organizationName, projectName],
  )

  const onTrackMixpanelPageViewedEvent = useCallback(
    (subPage: string) => {
      dispatch(
        trackMixpanelPageViewedEvent({
          subPage,
          path: createContainerGroupPageRoutePath,
          organizationName,
          projectName,
        }),
      )
    },
    [dispatch, organizationName, projectName],
  )

  useEffect(() => {
    dispatch(getCreateContainerGroupWizardData({ organizationName, projectName }))
  }, [dispatch, organizationName, projectName])

  useEffect(() => {
    // TODO: There is some unintended re-render that causes this cleanup to be called before the component is unmounted.
    // In production, this would work as intended and only be fired when the component is unmounted. But in development, to test
    // the duplicate container group functionality you will have to remove react strict mode.
    return () => {
      dispatch(duplicateContainerGroupRemoved(organizationName))
    }
  }, [dispatch, organizationName])

  return isGetCreateContainerGroupWizardPageDataPending ? (
    <LoadingPage />
  ) : (
    <CreateContainerGroupPage
      duplicateContainerGroup={duplicateContainerGroup}
      gpuOptions={gpuOptions}
      isBillingInformationMissingModalShowing={isBillingInformationMissingModalShowing}
      isContainerGatewayOptionsEnabled={containerGatewayOptionsEnabled}
      isContainerGroupDeploymentQuotaExceededModalShowing={isContainerGroupDeploymentQuotaExceededModalShowing}
      isContainerGroupInstancesQuotaExceededModalShowing={isContainerGroupInstancesQuotaExceededModalShowing}
      isContainerGroupPriorityEnabled={containerGroupPriorityEnabled}
      isJobQueueAutoscalerEnabled={jobQueueAutoscalerEnabled}
      isLeftColumnOpen={isLeftColumnOpen}
      isSubmitPending={isCreateContainerPending}
      linkToRequestIncreaseForContainerGroupInstancesQuotas={linkToRequestIncreaseForContainerGroupInstancesQuotas}
      linkToRequestIncreaseForContainerGroupQuotas={linkToRequestIncreaseForContainerGroupQuotas}
      maxReplicaCount={maxReplicaCount}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onEnterBillingInformation={() => navigate(getOrganizationBillingPagePath(organizationName))}
      onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
      onSetBillingInformationMissingModalShowingState={(showing) => handleSetModalShowingState('billing', showing)}
      onSetContainerGroupDeploymentsQuotaExceededModalShowingState={(showing) =>
        handleSetModalShowingState('deploymentsQuota', showing)
      }
      onSetContainerGroupInstancesQuotaExceededModalShowingState={(showing) =>
        handleSetModalShowingState('instancesQuota', showing)
      }
      onSubmit={(values) => onCreateContainerGroup(values)}
      onTrackMixpanelPageViewedEvent={onTrackMixpanelPageViewedEvent}
      onTrackMixpanelEvent={onTrackMixpanelEvent}
      organizationDisplayName={organizationDisplayName ?? organizationName}
      projectDisplayName={projectDisplayName ?? projectName}
      queues={jobQueues}
      ramOptions={ramOptions}
      storageOptions={storageOptions}
    />
  )
}
