import type {
  ContainerGroupPriority,
  ContainerResourceRequirements,
  RamOption,
  StorageOption,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { SvgIcon } from '../../../../components/base'
import { HardDriveIcon } from '../../../../components/icons'
import type { ClientGpuClass } from '../../../../features/gpuClasses/models'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import { getSelectedRamOption } from '../../../../utils/ramOptions'
import { convertStorageOptionToGB, getSelectedStorageOption } from '../../../../utils/storageOptions'
import { checkIfAllGpuClassesAreHighDemand } from '../../../CreateContainerGroup/components/GPUInput/utils'
import CPU from './assets/CPU.svg'
import GPU from './assets/GPU.svg'
import Memory from './assets/Memory.svg'
import { AllocatedResourceCard } from './components'
import { ContainerGroupsAllocatedResourcesMessages } from './messages'
import { getGpuClasses } from './utils'

export interface ContainerGroupsAllocatedResourcesProps {
  /** The data of the GPU Classes */
  gpuClasses?: ClientGpuClass[]
  /** The priority */
  priority?: ContainerGroupPriority | null
  /** The list of available ram options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The container group resources. */
  resources: ContainerResourceRequirements
  /** The container group replicas. */
  replicas: number
  /** The flag indicating if all gpu classes should have a card showing. */
  showAllGpuClasses?: boolean
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
  /** Indicates whether to show title or not. */
  withTitle?: boolean
}

export const ContainerGroupsAllocatedResources: FunctionComponent<ContainerGroupsAllocatedResourcesProps> = ({
  gpuClasses,
  priority,
  ramOptions,
  replicas,
  resources,
  showAllGpuClasses,
  storageOptions,
  withTitle = true,
}) => {
  const intl = useIntl()
  const selectedGpus = getGpuClasses(gpuClasses || [], resources.gpuClasses || [])
  const hasGpus = selectedGpus.length > 0
  const allGPUsSelectedAreHighDemand = checkIfAllGpuClassesAreHighDemand(selectedGpus)
  const hasMultipleGpuClasses = selectedGpus.length > 1
  const ram = getSelectedRamOption(ramOptions, resources.memory)
  const storage = getSelectedStorageOption(storageOptions, resources.storageAmount)

  return (
    <div className="flex flex-col">
      {withTitle && (
        <p className="mb-2 font-bold">
          {intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.allocatedResourcesLabel)}
        </p>
      )}
      <div className="flex flex-wrap gap-4">
        {/* Replicas */}
        <AllocatedResourceCard justifyCenter>
          <p className="font-medium">
            <FormattedMessage
              defaultMessage="{replicas, number} {replicas, plural, one {Replica} other {Replicas}}"
              id="32tiSO7k"
              description="The label for the replicas."
              values={{
                replicas: replicas,
              }}
            />
          </p>
        </AllocatedResourceCard>

        {/* CPU */}
        <AllocatedResourceCard>
          <img
            src={CPU}
            className="mx-2"
            alt={intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.noCPUImageAlt)}
          />
          <p className="font-medium">
            {intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.cpu, {
              cpu: resources.cpu,
            })}
          </p>
        </AllocatedResourceCard>

        {/* Ram  */}
        {ram && (
          <AllocatedResourceCard>
            <img
              src={Memory}
              className="mx-2"
              alt={intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.noMemoryImageAlt)}
            />
            <div className="pr-2">
              {ram.isHighDemand && (
                <p className={'text-base font-medium text-red-80'}>
                  {intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.highDemand)}
                </p>
              )}
              <p className="font-medium">{ram.name}</p>
            </div>
          </AllocatedResourceCard>
        )}

        {/* GPUs */}
        {hasGpus && (
          <>
            {showAllGpuClasses ? (
              <>
                {selectedGpus.map((gpuClass, index) => (
                  <AllocatedResourceCard key={index}>
                    <div className="flex items-center">
                      <img
                        src={GPU}
                        className="mx-2"
                        alt={intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.noGPUImageAlt)}
                      />
                      <div>
                        {gpuClass.isHighDemand && (
                          <p className={'text-base font-medium text-red-80'}>
                            {intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.highDemand)}
                          </p>
                        )}
                        <p className="font-medium">{gpuClass.name}</p>
                      </div>
                    </div>
                  </AllocatedResourceCard>
                ))}
              </>
            ) : (
              <AllocatedResourceCard>
                <div className="flex items-center">
                  <img
                    src={GPU}
                    className="mx-2"
                    alt={intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.noGPUImageAlt)}
                  />
                  <div>
                    {allGPUsSelectedAreHighDemand && (
                      <p className={'text-base font-medium text-red-80'}>
                        {intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.highDemand)}
                      </p>
                    )}
                    <p className="font-medium">
                      {hasMultipleGpuClasses
                        ? intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.multipleGpuClassesLabel)
                        : selectedGpus[0]?.name
                          ? selectedGpus[0].name
                          : intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.genericGpuLabel)}
                    </p>
                  </div>
                </div>
              </AllocatedResourceCard>
            )}
          </>
        )}

        {/* Storage */}
        {storage && (
          <AllocatedResourceCard>
            <div className="mx-2">
              <SvgIcon size={24}>
                <HardDriveIcon />
              </SvgIcon>
            </div>
            <div className="pr-2">
              {storage.isHighDemand && (
                <p className={'text-base font-medium text-red-80'}>
                  {intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.highDemand)}
                </p>
              )}
              <p className="font-medium">
                {intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.storageLabel, {
                  storage: convertStorageOptionToGB(storage.value),
                })}
              </p>
            </div>
          </AllocatedResourceCard>
        )}

        {/* Container Group Priority */}
        {priority && (
          <AllocatedResourceCard justifyCenter>
            <p className="mx-2 font-medium">
              {intl.formatMessage(ContainerGroupsAllocatedResourcesMessages.priorityLabel, {
                priority: capitalizeFirstLetter(priority),
              })}
            </p>
          </AllocatedResourceCard>
        )}
      </div>
    </div>
  )
}
