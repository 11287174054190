import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { AutoscalingFieldsMessages } from '../../../../../JobQueues/messages'
import { CreateContainerGroupField, CreateContainerGroupValues } from '../../../../models'

interface AutoscalingFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The maximum replica count allowed for the organization. */
  maxReplicaCount: number
}

export const AutoscalingFields: FunctionComponent<AutoscalingFieldsProps> = ({ control, maxReplicaCount }) => {
  const intl = useIntl()
  const jobQueue = useWatch({ control, name: CreateContainerGroupField.JOB_QUEUE })
  const autoscalingMinimumReplicas = useWatch({
    control,
    name: CreateContainerGroupField.AUTOSCALING_MINIMUM_REPLICAS,
  })
  const isAutoscalingMinimumReplicasZero = autoscalingMinimumReplicas === '0'

  return (
    <>
      <h2 className="mb-6 text-2xl font-bold">{intl.formatMessage(AutoscalingFieldsMessages.title)}</h2>
      <div className="mb-6">
        <Controller
          name={CreateContainerGroupField.AUTOSCALING_DESIRED_QUEUE_LENGTH}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                {...fieldState}
                defaultValue={field.value}
                helperText={intl.formatMessage(AutoscalingFieldsMessages.desiredQueueLengthHelperText)}
                label={intl.formatMessage(AutoscalingFieldsMessages.desiredQueueLengthLabel)}
                max={100}
                min={0}
                placeholder="10"
                isDisabled={jobQueue === undefined}
                isFullWidth
                type="number"
              />
            )
          }}
        />
      </div>
      <div className="mb-6">
        <Controller
          name={CreateContainerGroupField.AUTOSCALING_MINIMUM_REPLICAS}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                {...fieldState}
                defaultValue={field.value}
                helperText={
                  isAutoscalingMinimumReplicasZero
                    ? intl.formatMessage(AutoscalingFieldsMessages.minimumReplicasHelperTextWarning)
                    : intl.formatMessage(AutoscalingFieldsMessages.minimumReplicasHelperText)
                }
                label={intl.formatMessage(AutoscalingFieldsMessages.minimumReplicasLabel)}
                max={100}
                min={0}
                placeholder="1"
                isDisabled={jobQueue === undefined}
                isFullWidth
                type="number"
              />
            )
          }}
        />
      </div>
      <div className="mb-6">
        <Controller
          name={CreateContainerGroupField.AUTOSCALING_MAXIMUM_REPLICAS}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                {...fieldState}
                defaultValue={field.value}
                helperText={intl.formatMessage(AutoscalingFieldsMessages.maximumReplicasHelperText, {
                  max_replica_count: maxReplicaCount,
                })}
                label={intl.formatMessage(AutoscalingFieldsMessages.maximumReplicasLabel)}
                max={maxReplicaCount}
                min={1}
                placeholder={maxReplicaCount.toString()}
                isDisabled={jobQueue === undefined}
                isFullWidth
                type="number"
              />
            )
          }}
        />
      </div>
      <div className="mb-6">
        <Controller
          name={CreateContainerGroupField.AUTOSCALING_PERIOD}
          control={control}
          defaultValue={30}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                {...fieldState}
                defaultValue={30}
                helperText={intl.formatMessage(AutoscalingFieldsMessages.periodHelperText)}
                label={intl.formatMessage(AutoscalingFieldsMessages.periodLabel)}
                max={1800}
                min={15}
                placeholder="30"
                isDisabled={jobQueue === undefined}
                isFullWidth
                type="number"
              />
            )
          }}
        />
      </div>
      <div className="mb-6">
        <Controller
          name={CreateContainerGroupField.AUTOSCALING_MAXIMUM_UPSCALE_PER_MINUTE}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                {...fieldState}
                defaultValue={field.value}
                helperText={intl.formatMessage(AutoscalingFieldsMessages.maximumUpscalePerMinuteHelperText)}
                label={intl.formatMessage(AutoscalingFieldsMessages.maximumUpscalePerMinuteLabel)}
                max={100}
                min={1}
                placeholder="1"
                isDisabled={jobQueue === undefined}
                isFullWidth
                type="number"
              />
            )
          }}
        />
      </div>
      <div className="mb-6">
        <Controller
          name={CreateContainerGroupField.AUTOSCALING_MAXIMUM_DOWNSCALE_PER_MINUTE}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                {...fieldState}
                defaultValue={field.value}
                helperText={intl.formatMessage(AutoscalingFieldsMessages.maximumDownscalePerMinuteHelperText)}
                label={intl.formatMessage(AutoscalingFieldsMessages.maximumDownscalePerMinuteLabel)}
                max={100}
                min={1}
                placeholder="1"
                isDisabled={jobQueue === undefined}
                isFullWidth
                type="number"
              />
            )
          }}
        />
      </div>
    </>
  )
}
