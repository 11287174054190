import { defineMessages } from 'react-intl'

export const EditReplicaCountInputMessages = defineMessages({
  contactUsText: {
    defaultMessage: 'contact us',
    id: 'mz8yvuzc',
    description: 'The text for the for the contact support link.',
  },
  helperText: {
    defaultMessage:
      'Deploy with 2+ replicas to ensure the uptime of your deployment. Need to increase your replica count limit? please {contact_support}',
    id: 'MwM8PAxo',
    description: 'The helper text for the container group replica count field.',
  },
  helperTextWhenMaxIsOne: {
    defaultMessage: 'For higher counts, please {contact_support}',
    id: '49seLXe0',
    description:
      'The helper text for the container group replica count field when the max replica count available to the organization is one.',
  },
  label: {
    defaultMessage: 'Replica Count',
    id: 'Kxn+gD11',
    description: 'The label for the container group replica count field.',
  },
})
