import { CreateContainerGroupField } from './CreateContainerGroupField'
import { CreateContainerGroupFormSectionIdAttributes } from './CreateContainerGroupFormSectionIdAttributes'

export const CreateContainerGroupFieldToCreateContainerGroupFormSectionIdMapping: Partial<
  Record<CreateContainerGroupField, CreateContainerGroupFormSectionIdAttributes>
> = {
  [CreateContainerGroupField.NAME]: CreateContainerGroupFormSectionIdAttributes.CONTAINER_GROUP_NAME,

  [CreateContainerGroupField.IMAGE_SOURCE]: CreateContainerGroupFormSectionIdAttributes.IMAGE_SOURCE,
  [CreateContainerGroupField.IMAGE_TYPE]: CreateContainerGroupFormSectionIdAttributes.IMAGE_SOURCE,
  [CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER]: CreateContainerGroupFormSectionIdAttributes.IMAGE_SOURCE,

  [CreateContainerGroupField.REPLICA_COUNT]: CreateContainerGroupFormSectionIdAttributes.REPLICA_COUNT,

  [CreateContainerGroupField.ENVIRONMENT_VARIABLES]: CreateContainerGroupFormSectionIdAttributes.ENVIRONMENT_VARIABLES,

  [CreateContainerGroupField.COMMAND]: CreateContainerGroupFormSectionIdAttributes.COMMAND,
  [CreateContainerGroupField.COMMAND_ARGUMENTS]: CreateContainerGroupFormSectionIdAttributes.COMMAND,

  [CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED]: CreateContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY,
  [CreateContainerGroupField.CONTAINER_GATEWAY_PORT]: CreateContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY,
  [CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION]:
    CreateContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY,
  [CreateContainerGroupField.CONTAINER_GATEWAY_LOAD_BALANCER]:
    CreateContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY,
  [CreateContainerGroupField.CONTAINER_GATEWAY_LIMIT_SERVER]:
    CreateContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY,
  [CreateContainerGroupField.CONTAINER_GATEWAY_CLIENT_REQUEST_TIMEOUT]:
    CreateContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY,
  [CreateContainerGroupField.CONTAINER_GATEWAY_SERVER_RESPONSE_TIMEOUT]:
    CreateContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY,

  [CreateContainerGroupField.JOB_QUEUE]: CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE,
  [CreateContainerGroupField.JOB_QUEUE_PATH]: CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE,
  [CreateContainerGroupField.JOB_QUEUE_PORT]: CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE,
  [CreateContainerGroupField.AUTOSCALING_DESIRED_QUEUE_LENGTH]: CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE,
  [CreateContainerGroupField.AUTOSCALING_MINIMUM_REPLICAS]: CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE,
  [CreateContainerGroupField.AUTOSCALING_MAXIMUM_REPLICAS]: CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE,
  [CreateContainerGroupField.AUTOSCALING_PERIOD]: CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE,
  [CreateContainerGroupField.AUTOSCALING_MAXIMUM_UPSCALE_PER_MINUTE]:
    CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE,
  [CreateContainerGroupField.AUTOSCALING_MAXIMUM_DOWNSCALE_PER_MINUTE]:
    CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE,

  [CreateContainerGroupField.STARTUP_PROBE_ENABLED]: CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,
  [CreateContainerGroupField.STARTUP_PROBE_COMMAND]: CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,
  [CreateContainerGroupField.STARTUP_PROBE_COMMAND_ARGUMENTS]:
    CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,
  [CreateContainerGroupField.STARTUP_PROBE_PORT]: CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,
  [CreateContainerGroupField.STARTUP_PROBE_PATH]: CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,
  [CreateContainerGroupField.STARTUP_PROBE_PROTOCOL]: CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,
  [CreateContainerGroupField.STARTUP_PROBE_HEADERS]: CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,
  [CreateContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS]:
    CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,
  [CreateContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS]: CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,
  [CreateContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS]: CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,
  [CreateContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD]:
    CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,
  [CreateContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD]:
    CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE,

  [CreateContainerGroupField.LIVENESS_PROBE_ENABLED]: CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,
  [CreateContainerGroupField.LIVENESS_PROBE_COMMAND]: CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,
  [CreateContainerGroupField.LIVENESS_PROBE_COMMAND_ARGUMENTS]:
    CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,
  [CreateContainerGroupField.LIVENESS_PROBE_PORT]: CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,
  [CreateContainerGroupField.LIVENESS_PROBE_PATH]: CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,
  [CreateContainerGroupField.LIVENESS_PROBE_PROTOCOL]: CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,
  [CreateContainerGroupField.LIVENESS_PROBE_HEADERS]: CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,
  [CreateContainerGroupField.LIVENESS_PROBE_INITIAL_DELAY_SECONDS]:
    CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,
  [CreateContainerGroupField.LIVENESS_PROBE_PERIOD_SECONDS]: CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,
  [CreateContainerGroupField.LIVENESS_PROBE_TIMEOUT_SECONDS]:
    CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,
  [CreateContainerGroupField.LIVENESS_PROBE_FAILURE_THRESHOLD]:
    CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,
  [CreateContainerGroupField.LIVENESS_PROBE_SUCCESS_THRESHOLD]:
    CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE,

  [CreateContainerGroupField.READINESS_PROBE_ENABLED]: CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,
  [CreateContainerGroupField.READINESS_PROBE_COMMAND]: CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,
  [CreateContainerGroupField.READINESS_PROBE_COMMAND_ARGUMENTS]:
    CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,
  [CreateContainerGroupField.READINESS_PROBE_PORT]: CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,
  [CreateContainerGroupField.READINESS_PROBE_PATH]: CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,
  [CreateContainerGroupField.READINESS_PROBE_PROTOCOL]: CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,
  [CreateContainerGroupField.READINESS_PROBE_HEADERS]: CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,
  [CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS]:
    CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,
  [CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS]:
    CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,
  [CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS]:
    CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,
  [CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD]:
    CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,
  [CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD]:
    CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE,

  [CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE]:
    CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.AXIOM_API_KEY]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.AXIOM_HOST]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.AXIOM_DATASET_NAME]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.DATADOG_API]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.DATADOG_HOST]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.DATADOG_TAGS]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.HTTP_HOST]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.HTTP_PORT]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.HTTP_PATH]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.HTTP_USER]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.HTTP_PASSWORD]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.HTTP_FORMAT]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.HTTP_COMPRESSION]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.HTTP_HEADERS]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.NEW_RELIC_HOST]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.NEW_RELIC_INGESTION_KEY]:
    CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.SPLUNK_HOST]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.SPLUNK_TOKEN]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.TCP_HOST]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
  [CreateContainerGroupField.TCP_PORT]: CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE,
}
