import type { FieldErrors, FieldValues } from 'react-hook-form'
import { CreateContainerGroupField } from '../../models'

export const checkIfJobQueueFieldsHaveErrors = (errors: FieldErrors<FieldValues>): boolean => {
  return (
    (errors[CreateContainerGroupField.JOB_QUEUE_PATH] || errors[CreateContainerGroupField.JOB_QUEUE_PORT]) !== undefined
  )
}

export const getJobQueueDisplayName = (
  queues: { label: string; value: string }[],
  selectedQueueName: string,
): string => {
  return queues.find((queue) => queue.value === selectedQueueName)?.label ?? ''
}

export const jobQueueFieldsList: string[] = [
  CreateContainerGroupField.JOB_QUEUE,
  CreateContainerGroupField.JOB_QUEUE_PATH,
  CreateContainerGroupField.JOB_QUEUE_PORT,
  CreateContainerGroupField.AUTOSCALING_DESIRED_QUEUE_LENGTH,
  CreateContainerGroupField.AUTOSCALING_MINIMUM_REPLICAS,
  CreateContainerGroupField.AUTOSCALING_MAXIMUM_REPLICAS,
  CreateContainerGroupField.AUTOSCALING_PERIOD,
  CreateContainerGroupField.AUTOSCALING_MAXIMUM_DOWNSCALE_PER_MINUTE,
  CreateContainerGroupField.AUTOSCALING_MAXIMUM_UPSCALE_PER_MINUTE,
]
