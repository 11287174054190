import { type FunctionComponent, type ReactNode } from 'react'
import { ToastContainer } from '../notifications'

interface PublicRouteProps {
  children: ReactNode
}

export const PublicRoute: FunctionComponent<PublicRouteProps> = ({ children }) => {
  return (
    <>
      <ToastContainer />
      {children}
    </>
  )
}
