import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import { Link } from '../../../components/base'
import type { ToastNotificationType } from '../../models'
import { EmailNotSentContentMessage } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters an error when
 * attempting to register their account via email.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 * @param email The email address
 */
export const getEmailNotSentContent = (
  intl: IntlShape,
  email: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(EmailNotSentContentMessage.body, {
      contact_support: (
        <Link isDanger url="mailto:cloud@salad.com?subject=SaladCloud Support">
          {intl.formatMessage(EmailNotSentContentMessage.contactSupportLinkText)}
        </Link>
      ),
      email,
    }),
    type: 'error',
    title: intl.formatMessage(EmailNotSentContentMessage.title),
  }
}
