import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface helpScoutState {
  isHelpScoutBeaconFailedToOpenModalOpen: boolean
}

const initialState: helpScoutState = {
  isHelpScoutBeaconFailedToOpenModalOpen: false,
}

export const helpScoutSlice = createSlice({
  name: 'helpScout',
  initialState,
  reducers: {
    setIsHelpScoutBeaconFailedToOpenModalOpen(state, action: PayloadAction<{ isOpen: boolean }>) {
      state.isHelpScoutBeaconFailedToOpenModalOpen = action.payload.isOpen
    },
  },
})

export const { setIsHelpScoutBeaconFailedToOpenModalOpen } = helpScoutSlice.actions
