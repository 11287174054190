import type { Queue, RamOption, StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import { Dict } from 'mixpanel-browser'
import { useState, type FunctionComponent } from 'react'
import { Control, FieldValues, UseFormClearErrors, UseFormResetField, UseFormTrigger } from 'react-hook-form'
import type { ClientGpuOptions } from '../../../../features/gpuClasses/models'
import { CreateContainerGroupValues } from '../../models'
import { ContainerConfiguration } from '../ContainerConfiguration'
import { ContainerConnections } from '../ContainerConnections'
import { ContainerMonitoring } from '../ContainerMonitoring'
import { DockerRun } from '../DockerRun'
import { MachineHardware } from '../MachineHardware'
import { PresetContainer } from '../PresetContainer'

interface CreateContainerGroupMultiMainProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The current page index */
  currentPage: number
  /** The list of available GPU options to choose from. */
  gpuOptions: ClientGpuOptions
  /** Function to handle page change */
  handlePageChange: (page: number, isValidationRequired?: boolean) => void
  /** The flag indicating if the container gateway options are enabled. */
  isContainerGatewayOptionsEnabled: boolean
  /** The flag indicating if the container group priority input is enabled. */
  isContainerGroupPriorityEnabled: boolean
  /** The flag indicating if the job queue autoscaler is enabled. */
  isJobQueueAutoscalerEnabled: boolean
  /** The flag indicating that the request to create a container group is pending. */
  isSubmitPending: boolean
  /** The link to the typeform for requesting an increase in the container group instance quota limit. */
  linkToRequestIncreaseForContainerGroupInstancesQuotas: string
  /** The maximum replica count allowed for the organization. */
  maxReplicaCount: number
  /** The function to handle card click event. */
  onSelectPresetCard: (
    values?: Partial<CreateContainerGroupValues>,
    overrideRequiredFields?: Partial<Record<keyof CreateContainerGroupValues, boolean>>,
  ) => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   * @param dockerCommand The docker command that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string, dockerCommand?: string) => void
  /** The submit handler for the create container group react hook form. */
  onSubmit: (event: React.SyntheticEvent<Element, Event>) => void
  /**
   * A callback that when executed records a mixpanel page viewed event for the page that was viewed.
   *
   * @param event The event to record
   * @param properties The properties to record
   * @returns
   */
  onTrackMixpanelEvent: (event: string, properties: Dict) => void
  /** The available job queues to select from for a container group. */
  queues: Queue[]
  /** The list of available RAM options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The react hook form method that provides the ability to reset a specified field value. */
  resetField: UseFormResetField<FieldValues>
  /** The react hook form method that provides the ability to set a specified field value. */
  setValue: any
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const CreateContainerGroupMainContent: FunctionComponent<CreateContainerGroupMultiMainProps> = ({
  clearErrors,
  control,
  currentPage,
  gpuOptions,
  handlePageChange,
  isContainerGatewayOptionsEnabled,
  isContainerGroupPriorityEnabled,
  isJobQueueAutoscalerEnabled,
  isSubmitPending,
  linkToRequestIncreaseForContainerGroupInstancesQuotas,
  maxReplicaCount,
  onRecordMixpanelElementClickedEvent,
  onSelectPresetCard,
  onSubmit,
  onTrackMixpanelEvent,
  queues,
  ramOptions,
  resetField,
  setValue,
  storageOptions,
  trigger,
}) => {
  const [isDockerRunVisible, setIsDockerRunVisible] = useState(false)

  const handleDocker = () => {
    setIsDockerRunVisible(true)
  }

  const handleDockerNextStep = (
    values: Partial<CreateContainerGroupValues>,
    overrideRequiredFields: Partial<Record<keyof CreateContainerGroupValues, boolean>>,
  ) => {
    setIsDockerRunVisible(false)
    onSelectPresetCard(values, overrideRequiredFields)
  }
  return (
    <>
      {!isDockerRunVisible && currentPage === 0 && (
        <PresetContainer onSelectPresetCard={onSelectPresetCard} onDockerClick={handleDocker} />
      )}
      {isDockerRunVisible && (
        <DockerRun
          onBackButton={() => setIsDockerRunVisible(false)}
          onNextStep={handleDockerNextStep}
          onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
          onTrackMixpanelEvent={onTrackMixpanelEvent}
          ramOptions={ramOptions}
          storageOptions={storageOptions}
        />
      )}
      {currentPage === 1 && (
        <ContainerConfiguration
          clearErrors={clearErrors}
          control={control}
          isSubmitPending={isSubmitPending}
          linkToRequestIncreaseForContainerGroupInstancesQuotas={linkToRequestIncreaseForContainerGroupInstancesQuotas}
          maxReplicaCount={maxReplicaCount}
          onNextStep={() => handlePageChange(currentPage + 1)}
          onBackButton={() => handlePageChange(currentPage - 1, true)}
          resetField={resetField}
          setValue={setValue}
          trigger={trigger}
        />
      )}
      {currentPage === 2 && (
        <MachineHardware
          control={control}
          gpuOptions={gpuOptions}
          isContainerGroupPriorityEnabled={isContainerGroupPriorityEnabled}
          isSubmitPending={isSubmitPending}
          onBackButton={() => handlePageChange(currentPage - 1)}
          onNextStep={() => handlePageChange(currentPage + 1)}
          ramOptions={ramOptions}
          resetField={resetField}
          setValue={setValue}
          storageOptions={storageOptions}
        />
      )}
      {currentPage === 3 && (
        <ContainerConnections
          control={control}
          isSubmitPending={isSubmitPending}
          maxReplicaCount={maxReplicaCount}
          isContainerGatewayOptionsEnabled={isContainerGatewayOptionsEnabled}
          isJobQueueAutoscalerEnabled={isJobQueueAutoscalerEnabled}
          onBackButton={() => handlePageChange(currentPage - 1)}
          onNextStep={() => handlePageChange(currentPage + 1)}
          queues={queues}
          resetField={resetField}
          trigger={trigger}
        />
      )}
      {currentPage === 4 && (
        <ContainerMonitoring
          clearErrors={clearErrors}
          control={control}
          isSubmitPending={isSubmitPending}
          onBackButton={() => handlePageChange(currentPage - 1)}
          onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
          onSubmit={onSubmit}
          resetField={resetField}
          setValue={setValue}
          trigger={trigger}
        />
      )}
    </>
  )
}
