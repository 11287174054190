import type {
  ContainerGroupPriority,
  ContainerGroupState,
  ContainerResourceRequirements,
  RamOption,
  StorageOption,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Card } from '../../../../components/base/Card'
import { ContainerGroupsDeploymentTags } from '../../../../components/containerGroups/ContainerGroupsDeploymentTags'
import type { ClientGpuClass } from '../../../../features/gpuClasses/models'
import { ContainerGroupsAllocatedResources } from '../ContainerGroupsAllocatedResources'
import { ContainerGroupsImageSource } from '../ContainerGroupsImageSource'
import { ContainerGroupsCardMessages } from './messages'

export interface ContainerGroupsCardProps {
  /** The path for the container. */
  containerPath: string
  /** The container groups card create time. */
  createTime: Date
  /** The container groups card current state. */
  currentState: ContainerGroupState
  /** The container groups card displayed name. */
  displayName: string
  /** The data of the GPU Classes */
  gpuClasses?: ClientGpuClass[]
  /** The container groups card container image. */
  image: string
  /** The flag indicating the container group card is authentication required */
  isAuthRequired?: boolean
  /** The priority */
  priority?: ContainerGroupPriority | null
  /** The list of available ram options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The container groups card amount of replicas. */
  replicas: number
  /** The container groups card container resources. */
  resources: ContainerResourceRequirements
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
  /** The container group version. */
  version: number
}

export const ContainerGroupsCard: FunctionComponent<ContainerGroupsCardProps> = ({
  containerPath,
  createTime,
  currentState,
  displayName,
  gpuClasses,
  image,
  isAuthRequired,
  priority,
  ramOptions,
  replicas,
  resources,
  storageOptions,
  version,
}) => {
  const intl = useIntl()

  const { instanceStatusCounts, status: currentStatus } = currentState
  const { runningCount } = instanceStatusCounts

  return (
    <div className="mb-4">
      <Link to={containerPath} className="text-initial">
        <Card>
          <h3 className="text-xl font-bold">{displayName}</h3>
          <p className="text-xs text-neutral-70">
            {intl.formatMessage(ContainerGroupsCardMessages.createdDate, {
              created_date: intl.formatDate(createTime, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }),
              created_time: intl.formatDate(createTime, {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              }),
            })}
          </p>
          <div className="mt-2">
            <ContainerGroupsDeploymentTags
              currentStatus={currentStatus}
              isAuthRequired={isAuthRequired}
              replicas={replicas}
              replicasRunning={runningCount}
              version={version}
            />
          </div>
          <div className="mt-6">
            <ContainerGroupsImageSource image={image} />
          </div>
          <ContainerGroupsAllocatedResources
            gpuClasses={gpuClasses}
            priority={priority}
            ramOptions={ramOptions}
            resources={resources}
            replicas={replicas}
            storageOptions={storageOptions}
          />
        </Card>
      </Link>
    </div>
  )
}
