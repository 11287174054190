import type { AppState } from '../../store'
import { FeatureFlag } from './models'

export const selectContainerGroupPriorityEnabled = (state: AppState) =>
  state.featureFlags[FeatureFlag.Container_Group_Priority] === true

export const selectJobQueueAutoscalerEnabled = (state: AppState) =>
  state.featureFlags[FeatureFlag.Job_Queue_Autoscaler] === true

export const selectContainerGatewayOptionsEnabled = (state: AppState) =>
  state.featureFlags[FeatureFlag.Container_Gateway_Options] === true
