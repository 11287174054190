import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../Button'
import { SidePanelModalMessages } from './SidePanelModalMessages'

interface SidePanelModalProps {
  /** JSX that is used as a content of Side Panel Modal */
  children: ReactNode
  /**
   * An optional prop that allows for a custom button to be shown in place of the standard close button at the bottom of
   * the modal
   */
  CustomButton?: ReactNode
  /** An optional prop that allows for the modal to be larger than the default size. */
  isLarge?: boolean
  /** Indicates whether to show the Side Panel Modal or not */
  isShown: boolean
  /** Flag indicating whether to adjust the overlay zIndex to be stacked on top of another side panel modal */
  isStacked?: boolean
  /** The callback executed once the user click on the overlay or close button */
  onClose: () => void
  /** Title for dialog (accessability) */
  title: string
}

export const SidePanelModal: FunctionComponent<SidePanelModalProps> = ({
  children,
  CustomButton,
  isLarge,
  isShown,
  isStacked,
  onClose,
  title,
}) => {
  const intl = useIntl()

  return (
    <>
      {isShown && (
        <div
          onClick={onClose}
          aria-hidden="true"
          className={classNames('fixed left-0 top-0 h-full w-full bg-neutral-100 transition-opacity', {
            'opacity-30': isShown,
            'opacity-0': !isShown,
            'z-20': !isStacked,
            'z-30': isStacked,
          })}
        />
      )}
      <div
        className={classNames('fixed right-0 top-0 flex h-screen flex-col bg-neutral-10 pt-16 transition-transform', {
          'w-[559px]': !isLarge,
          'w-[859px]': isLarge,
          'translate-x-0': isShown,
          'translate-x-full': !isShown && !isLarge,
          'translate-x-[859px]': !isShown && isLarge,
          'z-20': !isStacked,
          'z-30': isStacked,
        })}
      >
        <div className="flex h-full flex-col justify-between">
          <div className="flex-1 overflow-y-auto" role="dialog" title={title}>
            {children}
          </div>
          <div className="flex w-full justify-center bg-neutral-10 px-14 py-7 shadow">
            {CustomButton ? (
              <>{CustomButton}</>
            ) : (
              <Button isFullWidth variant="green-filled" onClick={onClose}>
                {intl.formatMessage(SidePanelModalMessages.close)}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
